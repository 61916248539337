<template>
  <div>
    <b-container>
      <b-row class="fullscreen" align-h="center" align-v="center">
        <p class="dashboard-redirect-text">
          Дашборд переехал на новый адрес
          <a
            class="dashboard-redirect-link"
            href="https://dashboard.s-materials.ru/"
            >dashboard.s-materials.ru</a
          >
        </p>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "DashboardView",
};
</script>

<style lang="scss">
.dashboard-redirect-text {
  font-size: 18px;
}
.dashboard-redirect-link {
  font-size: 20px;
  font-weight: 700;
  color: $accent;
}
.dashboard-redirect-link:hover {
  font-size: 20px;
  font-weight: 700;
  color: $white;
}
</style>
